import React from 'react';

export const Footer = () => {
    return (
        <div>
            <footer class="page-footer font-small cyan darken-3" style={{borderTop: '1px solid black', marginTop: '50px'}}>

<div class="container">

  <div class="row">

    <div class="col-md-12 py-5">
      <div class="mb-5 flex-center">

        <a class="fb-ic" href="/impressum">
          <i class="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> Impressum</i>
        </a>
        <a class="tw-ic" href="/datenschutz">
          <i class="fab fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x">Datenschutz </i>
        </a>
        
      </div>
    </div>

  </div>

</div>

<div class="footer-copyright text-center py-3">© 2021 Copyright: PoolundGarten
  
</div>

</footer>
        </div>
    )
}

export default Footer
