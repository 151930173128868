import React from 'react';
import {Navbar, Nav, Row, Col, Button, Container, NavDropdown, Dropdown, DropdownButton} from 'react-bootstrap';
import './styles.css';
import leistungList from '../Data/Leistung';
import logo from '../../assets/logo-white.jpeg'

export const NavbarSticky = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="" style={{position: 'absolute', width: '100%'}}>
  <Navbar.Brand href="#home" /* style={{backgroundImage: 'url(' + logo + ')'}} */>
    <img src={logo} style={{width: '200px'}} />
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/" style={{fontSize: '1.2rem', fontWeight: 600}}>Home</Nav.Link>
      <Nav.Link href="/flyer" style={{fontSize: '1.2rem', fontWeight: 600}}>Flyer</Nav.Link>
      <NavDropdown title="Leistungen" id="collasible-nav-dropdown" variant="dark" style={{color: 'white !important', fontSize: '1.2rem', fontWeight: 600}}>
        {leistungList.map((leistung) => (
          <>
              <NavDropdown.Item href={"/" + leistung.title.toLowerCase()}>{leistung.title}</NavDropdown.Item>
          </>
        ))}
      </NavDropdown>
      
    </Nav>
  </Navbar.Collapse>
  <Navbar.Collapse className="justify-content-end">

   <Nav>
      {/* <Nav.Link href="Tel:+491717713125">
        Tel: 0171 77 13125
      </Nav.Link>
      <Nav.Link href="Tel:+4915901265925">
      0159 0126 5925
      </Nav.Link> */}
      <Nav.Link eventKey={2} href="mailto: info@ftj-construction.com">
      E-Mail: info@ftj-construction.com
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
        
    )
}



export default NavbarSticky
