import React, { Component, useEffect, useState } from 'react';
import {Container, Carousel} from 'react-bootstrap';
import './styles.css';
import sanityClient from '../../client.js';


const ProductPage = ({leistung}) => {


    return (
        <div>
            <Container className="startbackground2" fluid style={{backgroundImage: "url(" + leistung.imgPage + ")"}}>
                    <Container className="productHeadline">
                        <p className="headlineP">{leistung.title}</p>
                    </Container>
            </Container>
            <Container>
                <h2 className="bsp">Beispielprojekte</h2>
                {console.log(leistung)}
                <hr />
                
  {leistung.slider ?  
  (<Carousel>
      {leistung.slider.map((pic) => (
      <Carousel.Item>
      <img
        className="d-block w-100"
        src={pic}
        alt="First slide"
      />
      {/* <Carousel.Caption>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    
  ))}</Carousel>) : (<h3>Hier könnte ihr Projekt stehen</h3>)
}
  


            </Container>
        </div>
    )
}

export default ProductPage
