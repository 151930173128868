import pic4 from '../../assets/pic4.jpg';
import pic4p from '../../assets/pic4-1.jpg';
import pic5 from '../../assets/pic5.jpg';
import pic5p from '../../assets/pic5-1.jpg';
import pic6 from '../../assets/pic6.jpg';
import pic6p from '../../assets/pic6-1.jpg';
import pic7 from '../../assets/pic7.jpg';
import pic7p from '../../assets/pic7-1.jpg';
import pic8 from '../../assets/pic8.jpg';
import pic8p from '../../assets/pic8-1.jpg';
import pic9 from '../../assets/pic9.jpg';
import pic9p from '../../assets/pic9-1.jpg';
import pic10 from '../../assets/pic10.jpg';
import pic10p from '../../assets/pic10-1.jpg';
import pic11 from '../../assets/pic11.jpg';
import pic11p from '../../assets/pic11-1.jpg';
import pic12 from '../../assets/pic12.jpg';
import pic12p from '../../assets/pic12-1.jpg';
import pic13 from '../../assets/pic13.jpg';
import pic13p from '../../assets/pic13-1.jpg';
import pic14 from '../../assets/pic14.jpg';
import pic14p from '../../assets/pic14-1.jpg';
import pic15 from '../../assets/pic15.jpg';
import pic15p from '../../assets/pic15-1.jpg';
import pic16 from '../../assets/pic16.jpg';
import pic16p from '../../assets/pic16-1.jpg';
import pic17 from '../../assets/pic17.jpg';
import pic17p from '../../assets/pic17-1.jpg';
import slid1 from '../../assets/slider/gartengestaltung-1.jpeg';
import slid2 from '../../assets/slider/pool-1.jpeg';
import slid3 from '../../assets/slider/naturpool-1.jpeg';
import slid4 from '../../assets/slider/naturpool-2.jpeg';
import slid5 from '../../assets/slider/pflastern-1.jpeg';
import slid6 from '../../assets/slider/pflastern-2.jpeg';
import slid7 from '../../assets/slider/pflastern-3.jpeg';
import slid8 from '../../assets/slider/pflastern-4.jpeg';
import slid9 from '../../assets/slider/terasse-1.jpeg';
import slid10 from '../../assets/slider/terasse-2.jpeg';
import slid11 from '../../assets/slider/mauern-1.jpeg';
import slid12 from '../../assets/slider/mauern-2.jpeg';
import slid13 from '../../assets/slider/mauern-3.jpeg';
import slid14 from '../../assets/slider/lichtgestaltung-1.jpeg';
import slid15 from '../../assets/slider/lichtgestaltung-2.jpeg';
import ftj from '../../assets/ftj.jpg';
import ftj1 from '../../assets/ftj-1.png';
import slid16 from '../../assets/slider/ftj-1.jpeg';
import slid17 from '../../assets/slider/ftj-2.jpeg';
import slid18 from '../../assets/slider/ftj-3.jpeg';
import slid19 from '../../assets/slider/ftj-4.jpeg';
import slid20 from '../../assets/slider/ftj-5.jpeg';
import slid21 from '../../assets/slider/ftj-6.jpeg';
import slid22 from '../../assets/slider/ftj-7.jpeg';
import slid23 from '../../assets/slider/ftj-8.jpeg';


export const leistungList = [
    {
        title: 'Landschaftgestaltung',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic4,
        imgPage: pic4p,
        slider: [],
    },
    {
        title: 'Gartengestaltung',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic5,
        imgPage: pic5p,
        slider: [slid1],
    },
    
    {
        title: 'Swimmingpools',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic6,
        imgPage: pic6p,
        slider: [slid2],
    },
    {
        title: 'Naturpools',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic7,
        imgPage: pic7p,
        slider: [slid3, slid4],
    },
    {
        title: 'Bepflanzung',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic8,
        imgPage: pic8p,
        slider: [],
    },
    {
        title: 'Bewässerungsanlagen',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic9,
        imgPage: pic9p,
        slider: [],
    },
    {
        title: 'Pflasterarbeiten',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic10,
        imgPage: pic10p,
        slider: [slid5, slid6, slid7, slid8],
    },
    {
        title: 'Terrassenbau',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic11,
        imgPage: pic11p,
        slider: [slid9, slid10],
    },
    {
        title: 'Mauern',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic12,
        imgPage: pic12p,
        slider: [slid11, slid12, slid13],
    },
    {
        title: 'Zäune',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic13,
        imgPage: pic13p,
        slider: [],
    },
    {
        title: 'Mähroboter installieren',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic14,
        imgPage: pic14p,
        slider: [],
    },
    {
        title: 'Lichtgestaltung',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic15,
        imgPage: pic15p,
        slider: [slid14, slid15],
    },
    {
        title: 'Technikeinbindung',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic16,
        imgPage: pic16p,
        slider: [],
    },
    {
        title: 'Outdoorküchen',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, \
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.',
        img: pic17,
        imgPage: pic17p,
        slider: [],
    },
    {
        title: 'Generalunternehmer für Neubau und Sanierung',
        text: 'Garten- und Landschaftsbau, Wohnbauten (Mehrfamilienhäuser, Einfamilienhäuser), Geschäftshäuser, Sozialimmobilien.',
        img: ftj1,
        imgPage: ftj,
        slider: [slid16, slid17, slid18, slid19, slid20, slid21, slid22, slid23],
    },
];

export default leistungList;