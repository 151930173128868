import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './styles.css';
import leistungsList from '../Data/Leistung';
import {Container, Row, Col, Button, Carousel, Card, Accordion} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Formular from '../Formular/Formular';
import startpic from '../../assets/startpic2.jpg';
import startCol1 from '../../assets/startCol1.jpg';
import startCol2 from '../../assets/startCol2.jpg';
import carousel1 from '../../assets/stock8.jpg';
import carousel2 from '../../assets/stock6.jpg';
import carousel3 from '../../assets/stock22.jpg';
import stock1 from '../../assets/stock1.jpg';
import stock2 from '../../assets/pic2.jpg';
import stock3 from '../../assets/stock3.jpg';
import stock4 from '../../assets/pic3.jpg';
import stock5 from '../../assets/pic1.jpg';
import video from '../../assets/video.mp4';


export const Startpage = () => {
    return (
        <>
        <Container fluid className="startbackground" style={{backgroundImage: "url(" + stock5 + ")", backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="overlay"></div>
            <Container style={{zIndex: 9999}}>
                <h1 className="h1">Pool und Garten</h1> 
                <p style={{color: 'white', fontWeight: 700, maxWidth: '700px'}}>Wir helfen Ihnen Ihren Traum vom Garten oder Pool zu verwirklichen. Egal ob Bewässerungsanlagen, Outdoorküchen oder Terassen, wir helfen gerne.</p>
                <a href="formRef">
                <Button style={{fontWeight: 700, fontSize: '1.5rem', padding: '1rem', margin: '2% 0', backgroundColor: '#5aba52', border: 'none', boxShadow: '0 0 10px 0 rgba(0,0,0,.3)'}}>Kontakt aufnehmen</Button>
                </a>
            </Container>
        </Container>
        <Container fluid style={{marginBottom: '100px'}}>
            <Row>
                <Col xs={12} md={6}>
                    <Container style={{height: '650px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{padding: '5%'}}>
                        <h2 style={{fontWeight: 700, textAlign: 'left' }}>Alles aus einer Hand</h2>
                        <p >Nach diesem Motto werden die Projekte von unseren vielseitig geschulten Mitarbeitern ausgeführt. 
                          Das Team das Ihren Gartentraum verwirklicht, führt sämtliche Leistungen selber aus. Ob Erd- oder Kanalarbeiten, Pflaster
                          und Terassen, Mauern und Treppenanlagen. Teichbau, Poolbau, Zaunbau und natürlich auch Ansprechpartner. Außerdem auch Rasen- und  
                          Pflanzenarbeiten.
                        </p>
                        </div>
                    </Container>
                </Col>
                <Col xs={12} md={6} style={{backgroundImage: "url(" + stock2 + ")", backgroundSize: 'cover', minHeight: '50vh', backgroundPosition: 'center'}}>
                
                </Col>
            </Row>
            <Row>
            <Col xs={12} md={6} style={{backgroundImage: "url(" + stock4 + ")", backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                
                </Col>
                <Col xs={12} md={6} style={{backgroundColor: '#5aba52', minHeight: '50vh'}}>
                    <Container style={{height: '650px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{padding: '5%'}}>
                        <h2 style={{color: 'white', fontWeight: 700, textAlign: 'left' }}>Wir planen Ihren Garten so, dass Sie sich darin wohlfühlen.</h2>
                        <p style={{color: 'white', }}>{/* One of the many beauties of public food garden projects is that they require minimal investment to start and often enjoy the enthusiastic support of community members. */}</p>
                        </div>
                    </Container>
                </Col>
               
            </Row>
        </Container>
        <Container className="sectionContainer" >
            <video src={video} controls style={{width: 'inherit'}}>

            </video>
            
        </Container>
       
        <Container className="sectionContainer" fluid style={{padding: '10rem 10%'}}>
          <h2>Unsere Leistungen</h2>
            <Row>

              {leistungsList.map((leistung) => (
                <>
                  <Col xs={12} md={4} sm={6} id={leistung.id} style={{textAlign: 'left'}}>
                    <Card style={{margin: '30px auto'}} >
                      <Card.Img variant="top" src={leistung.img} style={{height: '300px', objectFit: 'cover'}} />
                      <Card.Body>
                        <Card.Title>{leistung.title}</Card.Title>
                        <Card.Text>
                          Brauchen Sie Hilfe, Beratung oder einfach nur einen guten Tipp zum Thema {leistung.title}?
                          Kontaktieren Sie uns oder gucken Sie sich unsere Beispielprojekte an. 
                        </Card.Text>
                      <a href={"/" + leistung.title}>
                          <Button variant="primary" style={{backgroundColor: '#5aba52', border: 'none', fontWeight: 700}}>Mehr erfahren</Button>
                          </a>
                      </Card.Body>
                      </Card>
                  </Col>
                </>
              ))}
            </Row>
        </Container>


        <Container className="sectionContainer" fluid style={{backgroundColor: '#5aba52', padding: '5rem 2%'}}>
            
            <h2 style={{color: 'white'}}>Unser grüner Daumen</h2>
            
            <hr style={{margin: '5rem 0'}} />

            <Row style={{display: 'flex',justifyContent: 'space-around' }}>

            <Col style={{textAlign: 'center'}} xs={12} md={4}>
            <CountUp end={25} redraw={true} > 
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}}>gebaute Pools</h3>
              </Col>   
            

          <Col style={{textAlign: 'center'}} xs={12} md={4}>
          <CountUp end={150} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}}>verschönerte Gärten</h3>
          </Col> 
    

          <Col style={{textAlign: 'center'}} xs={12} md={4}>
          <CountUp end={50} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}} >stabile Einfahrten</h3>
          </Col> 
    
            </Row>

            <hr style={{margin: '5rem 0'}} />

            <Container className="sectionContainer" style={{textAlign: 'left'}}>
            <Accordion defaultActiveKey="0">
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="0" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Urlaubsfeeling
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>
        Mit einer hoch innovativen Technik zu erschwinglichen Preisen haben wir schon viele Poolanlagen realisiert, häufig auch mit
        einer Neugestaltung des Gartens einhergehend. Mit unseren Naturpools und Schwimmteichen können auch Sie sich das Urlaubsfeeling
        in Ihren Garten holen und egal welche Größe Ihr Pool hat: Mit einer leistungsstarken Gegenstromanlage können Sie schon ganz schön
        weit herausschwimmen. Auch eine Poolsanierung ist bei uns problemlos möglich.
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="1" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Die Planung
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>
        Im Wandel der Zeit haben sich auch die Anforderungen und Ansprüche an den eigenen Garten verändert. Opa's Nutzgarten und Oma's Rosengarten gibt es nicht mehr.
        Der Garten ist die Erweiterung es Wohnzimmers. Wellnesoase und Ausgleic hfür den Alltagsstress bei der Arbeit.
        <br /><br />
        Wir erwarten nicht, dass Sie mit fertigen Plänen zu uns kommen. Wir hoffen aber, dass Sie schon einige Ideen mitbringen.
        Ob Ihr Garten mordern, klassisch oder mediterran gestaltet werden soll, wir haben die passenden Konzepte.
        <br /><br />
        Mehr als 2 Jahrzehnte Erfahrung in allen Bereichen des Garten- und Landschaftsbaus stehen nicht nur für ausgewähltes Design, sondern
        auch für technisch anspruchsvolle Lösungen.
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="2" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Unsere Philosophie
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>
        Die Ansprüche und Bedürfnisse die heutzutage an einen Garten gestellt werden, haben sich stark gewandelt. Die individulle Gestaltung Ihres grünen Wohnzimmers ist unser Ziel. Wir haben
        den Anspruch Ihre Wünsche und Träume zu verwirklichen.
        <br /><br />
        Mit der dazu notwendigen Kompetenz, langjähriger Erfahrung nd einem hochmotiviertem Team, dass die gemeinsam geplanten und erarbeiteten Konzepte umsetzt, werden wir auch Sie und Ihre Familie
        zufriedenstellen.
        <br /><br />
        Wir beraten Sie bei der Auswahl der Materialien. Das entsprechende Fachwissen, wie Pflanzenkenntnisse und die Anwendung von gültigen Normen können Sie voraussetzten.
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
            </Container>
            <p style={{fontSize: '1.9rem', fontWeight: 600, color: 'white'}}>
            E-Mail: <a href="mailto: info@ftj-construction.com" style={{color: 'white'}}>info@ftj-construction.com</a> 
            
            
             <br /><br />
             Schreiben Sie uns!
            </p>
            </Container>

          



            
        </>
    )
}

export default Startpage
