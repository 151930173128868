import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import {Startpage} from './components/StartPage/Startpage.jsx';
import {NavbarSticky} from './components/Navbar/NavbarSticky';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import leistungList from './components/Data/Leistung';
import Test from './components/test/test';
import ProductPage from './components/ProductPage/ProductPage';
import Formular from './components/Formular/Formular';
import Flyer from './components/Flyer/Flyer.jsx';
import Impressum from './components/law/Impressum';
import Datenschutz from './components/law/Datenschutz';

function App() {

  


  return (
    	<Router>

    {/* add preloader
    https://codepen.io/juergengenser/pen/dPoQpY
    */}

      <NavbarSticky />
        <Switch>
          <Route exact path="/">
          <Startpage />
          </Route>
          <Route exact path="/test">
          <Test />
          </Route>
          <Route exact path="/flyer">
            <Flyer />
          </Route>
          <Route exact path="/impressum">
            <Impressum />
          </Route>
          <Route exact path="/datenschutz">
              <Datenschutz />
          </Route>
          {console.log(leistungList[3].title)}

        {leistungList.map((leistung) => (
            <Route exact path={"/" + leistung.title.toLowerCase()} key={leistung.id}>
                <ProductPage
                leistung={leistung}
                />
                {console.log(leistung)} 
            </Route>
        ))}

        
        </Switch>
        <Formular />
      <Footer
      />
      </Router>
      
          

  );
}

export default App;
