import React from 'react';
import {Button, Form, Container} from 'react-bootstrap';
import emailjs from 'emailjs-com';
import leistungList from '../Data/Leistung';

export const Formular = () => {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_giix2ts', 'template_4ag7mgs', e.target, 'user_HiOP0H3k2LFz2xNyBYlSZ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

    return (
        <Container id="formRef">
          <hr style={{margin: '50px 0'}} />
          <h3 style={{textAlign: 'center', marginBottom: '50px'}}>Kontaktiere Uns jederzeit!</h3>
                <Form onSubmit={sendEmail} name="test">
  <Form.Group controlId="formBasicEmail"  >
    <Form.Label>E-Mail</Form.Label>
    <Form.Control required type="email" placeholder="E-Mail Adresse" size="lg" name="email" />
  </Form.Group>

  <Form.Group>
  <Form.Label>Telefonnummer</Form.Label>
    <Form.Control required type="tel" placeholder="Telefonnummer" size="lg" name="tel" />

    <Form.Text className="text-muted">
      Wir geben die Daten nicht an Dritte weiter.
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="formBasicCheckbox">
  <Form.Group controlId="exampleForm.SelectCustomSizeLg">
    <Form.Label>Thema wählen</Form.Label>
    <Form.Control as="select" size="lg" custom name="topic">
      <option>Allgemein</option>
      {leistungList.map((leistung) => (
        <>
          <option key={leistung.id}>{leistung.title}</option>
        </>
      ))}
    </Form.Control>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Ihre Nachricht an uns</Form.Label>
    <Form.Control required as="textarea" rows={3} name="text"/>
  </Form.Group>
  
    <Form.Check type="checkbox" label="Ich stimme der Datenschutzvereinbarung zu" required />
  </Form.Group>
  <Button variant="primary" type="submit" style={{backgroundColor: '#5aba52', fontWeight: 700, border: 'none'}}>
    Absenden
  </Button>
</Form>     
        </Container>
    )
}

export default Formular
