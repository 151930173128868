import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './styles.css';
import leistungsList from '../Data/Leistung';
import {Container, Row, Col, Button, Carousel, Card, Accordion} from 'react-bootstrap';
import Formular from '../Formular/Formular';
import startpic from '../../assets/startpic2.jpg';
import startCol1 from '../../assets/startCol1.jpg';
import startCol2 from '../../assets/startCol2.jpg';
import carousel1 from '../../assets/carousel1.jpg';
import carousel2 from '../../assets/carousel2.jpg';
import carousel3 from '../../assets/carousel3.jpg';
import stock1 from '../../assets/stock1.jpg';
import stock2 from '../../assets/stock2.jpg';
import stock3 from '../../assets/stock3.jpg';
import stock4 from '../../assets/stock4.jpg';
import stock5 from '../../assets/stock5.jpg';


export const Test = () => {
    return (
        <>
        <Container fluid className="startbackground" style={{backgroundImage: "url(" + startpic + ")", backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="overlay"></div>
            <Container style={{zIndex: 9999}}>
                <h1 className="h1">Pool und Garten</h1>
                <p style={{color: 'white', fontWeight: 700, maxWidth: '700px'}}>We're a Maine-based nonprofit providing grants, crowdfunding opportunities and training to food garden projects across the country and around the world.</p>
                <Button style={{fontWeight: 700, fontSize: '1.5rem', padding: '1rem', margin: '2% 0', backgroundColor: '#5aba52', border: 'none', boxShadow: '0 0 10px 0 rgba(0,0,0,.3)'}}>Kontakt aufnehmen</Button>
            </Container>
        </Container>
        <Container fluid style={{marginBottom: '100px'}}>
            <Row>
                <Col>
                    <Container style={{height: '650px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{padding: '5%'}}>
                        <h2 style={{fontWeight: 700, textAlign: 'left' }}>Garden funding can be easy</h2>
                        <p >One of the many beauties of public food garden projects is that they require minimal investment to start and often enjoy the enthusiastic support of community members.</p>
                        </div>
                    </Container>
                </Col>
                <Col style={{backgroundImage: "url(" + startCol1 + ")", backgroundSize: 'cover'}}>
                
                </Col>
            </Row>
            <Row>
            <Col style={{backgroundImage: "url(" + startCol2 + ")", backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                
                </Col>
                <Col style={{backgroundColor: '#5aba52'}}>
                    <Container style={{height: '650px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{padding: '5%'}}>
                        <h2 style={{color: 'white', fontWeight: 700, textAlign: 'left' }}>Garden funding can be easy</h2>
                        <p style={{color: 'white', }}>One of the many beauties of public food garden projects is that they require minimal investment to start and often enjoy the enthusiastic support of community members.</p>
                        </div>
                    </Container>
                </Col>
               
            </Row>
        </Container>
        <Container className="sectionContainer" >
            <h2 style={{margin: '50px auto'}}>Unsere Leistungen</h2>
            <Carousel style={{maxHeight: '500px !important'}}> 
  <Carousel.Item interval={1000} style={{height: '500px'}} >
    <img
      className="d-block w-100"
      src={carousel1}
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      <Button>zur Leistung</Button>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={500} style={{height: '500px'}}>
    <img
      className="d-block w-100"
      src={carousel2}
      alt="Third slide"
    />
    <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <Button>zur Leistung</Button>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item style={{height: '500px'}}>
    <img
      className="d-block w-100"
      src={carousel3}
      alt="Third slide"
    />
    <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      <Button>zur Leistung</Button>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        </Container>
        {/* <Container>
            <h2>Count Up</h2>
            <CountUp
            end={100}
            duration={5}
            />
        </Container> */}
        <Container className="sectionContainer" fluid style={{padding: '10rem 10%'}}>
          <h2>Unsere Leistungen</h2>
          {/*  */}
            <Row /* style={{display: 'flex', justifyContent: 'center'}} */>

              {leistungsList.map((leistung) => (
                <>
                  <Col xs={12} md={4} sm={6} id={leistung.id}>
                    <Card style={{margin: '30px auto'}} >
                      <Card.Img variant="top" src={leistung.img} style={{height: '300px', objectFit: 'cover'}} />
                      <Card.Body >
                        <Card.Title>{leistung.title}</Card.Title>
                        <Card.Text>
                          {leistung.text}
                        </Card.Text>
                          <Button variant="primary" style={{backgroundColor: '#5aba52', border: 'none', fontWeight: 700}}>Go somewhere</Button>
                      </Card.Body>
                      </Card>
                  </Col>
                </>
              ))}
            </Row>
        </Container>


        <Container className="sectionContainer" fluid style={{backgroundColor: '#5aba52', padding: '5rem 2%'}}>
            
            <h2 style={{color: 'white'}}>Our Impacts are growing</h2>
            
            <hr style={{margin: '5rem 0'}} />

            <div style={{display: 'flex',justifyContent: 'space-around' }}>

            <div style={{textAlign: 'center'}}>
            <CountUp end={100} redraw={true} >
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}}>people reached</h3>
              </div>   
            

          <div style={{textAlign: 'center'}}>
          <CountUp end={1500} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}}>people reached</h3>
          </div> 
    

          <div style={{textAlign: 'center'}}>
          <CountUp end={420} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} style={{fontSize: '50px', fontWeight: 700, color: 'white', textAlign: 'center'}} />
              
            </VisibilitySensor>
        )}
    </CountUp>
    <h3 style={{color: 'white'}} >people reached</h3>
          </div> 
    
            </div>

            <hr style={{margin: '5rem 0'}} />

            <Container className="sectionContainer">
            <Accordion defaultActiveKey="0">
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="0" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Click me!
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>Hello! I'm the body</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="1" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Click me!
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>Hello! I'm the body</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card style={{border: 'none'}}>
    <Accordion.Toggle as={Card.Header} eventKey="2" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '2rem', fontWeight: 700}}>
      Click me!
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2" style={{backgroundColor: '#5aba52', color: 'white', fontSize: '1.4rem', fontWeight: 500, }}>
      <Card.Body>Hello! I'm the body</Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
            </Container>
            </Container>

          <Container>
          <Formular />
          </Container>



            
        </>
    )
}

export default Test
