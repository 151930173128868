import React from 'react';
import {Container} from 'react-bootstrap';
import fl1 from '../../assets/0001.jpg';
import fl2 from '../../assets/0002.jpg';


const Flyer = () => {
    return (
        <Container style={{textAlign: 'center', paddingTop: '150px'}}>
            <img src={fl1} style={{width: '60%', minWidth: '300px'}} />
            <img src={fl2} style={{width: '60%', minWidth: '300px'}} />
        </Container>
    )
}

export default Flyer
